<template>
  <div
    v-if="visible || preview"
    class="delay-modal"
    :class="{ preview: preview }"
  >
    <p v-html="text"></p>

    <div class="is-flex">
      <button
        @click.prevent="storeAndClose"
        class="button is-small is-primary is-outlined"
      >
        Agora não
      </button>
      <a
        @click.prevent="storeAndGo"
        :href="ctaLink"
        class="button is-small is-warning"
        >{{ ctaText }}</a
      >
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { setCookie, checkCookie } from "utils/cookies";

export default {
  props: {
    text: String,
    ctaText: String,
    ctaLink: String,
    delay: Number,
    preview: Boolean,
  },
  setup(props) {
    if (props.preview) return;

    const mixpanelProps = {
      "Title": document.title,
      "CTA Text": props.ctaText,
      "CTA Link": props.ctaLink,
    };

    const visible = ref(false);
    onMounted(() => {
      setTimeout(() => {
        visible.value = !checkCookie("delay_modal");
        mixpanel.track("Delay Modal - Pageview", mixpanelProps);
      }, props.delay * 1000);
    });

    const storeAndClose = () => {
      mixpanel.track("Delay Modal - Closed", mixpanelProps);

      setCookie("delay_modal", true, 6 * 60 * 60);
      visible.value = false;
    };

    const storeAndGo = () => {
      setCookie("delay_modal", true, 6 * 60 * 60);

      setTimeout(() => window.open(props.ctaLink, "_blank").focus(), 300);

      mixpanel.track("Delay Modal - CTA Clicked", mixpanelProps, () => {
        visible.value = false;
        window.open(props.ctaLink, "_blank").focus();
      });
    };

    return {
      visible,
      storeAndClose,
      storeAndGo,
    };
  },
};
</script>
