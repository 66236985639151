/* Based on CookieManager.js | v1.1.2 |
 * https://github.com/Gigabyte5671/CookieManager
 */

const domains = {
  development: "",
  staging: "ratsap.com.br",
  production: "erural.net",
};

const domainName = domains[import.meta.env.VITE_RAILS_ENV];

const setCookie = (cookieName, cookieValue, lifespanInSeconds) => {
  let d = new Date();
  d.setTime(d.getTime() + lifespanInSeconds * 1000);
  let expires = d.toUTCString();

  return (document.cookie = `${cookieName}=${cookieValue};expires=${expires};path=/;domain=${domainName};SameSite=Strict;`);
};

const getCookie = (cookieName) => {
  let name = `${cookieName}=`;
  let cookieArray = document.cookie.split(";");

  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];

    while (cookie.charAt(0) == " ") {
      cookie = cookie.substring(1);
    }

    if (cookie.indexOf(name) == 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return null;
};

const checkCookie = (cookieName) => {
  return !!cookieName && document.cookie.includes(`${cookieName}=`);
};

const clearCookie = (cookieName) => {
  setCookie(cookieName, "", -1);
};

export { setCookie, getCookie, checkCookie, clearCookie };
