import { reactive, ref, watch } from "vue";
import api from "services/api";
import { auctions_search_batches_url } from "utils/routes";

const requestBatches = ref([]);
const page = ref(1);
const hasMore = ref(true);
const isLoading = ref(false);
const auctionUrlFriendly = ref({});

const bottomSheetState = reactive({
  isActive: false,
  chosenOrdination: "Padrão",
  ordinationTypes: [
    { type: "batch_number", name: "Padrão", active: true },
    { type: "highest_price", name: "Maior preço", active: false },
    { type: "lowest_price", name: "Menor preço", active: false },
    { type: "highest_iabcz", name: "Maior iABCZ", active: false },
    { type: "highest_age", name: "Maior idade", active: false },
    { type: "lowest_age", name: "Menor idade", active: false },
  ],
});

watch(bottomSheetState, () => {
  const ordination = bottomSheetState.ordinationTypes.find((o) => o.active);
  bottomSheetState.chosenOrdination = ordination.name;
});

const toggleActive = () => {
  bottomSheetState.isActive = !bottomSheetState.isActive;
};

const seeMore = (mixpanelData) => {
  mixpanel.track("Event - More Products Clicked", mixpanelData);

  page.value++;

  const currentOrdination = bottomSheetState.ordinationTypes.find(
    (o) => o.active == true,
  );

  api
    .get(
      auctions_search_batches_url({
        url_friendly: auctionUrlFriendly.value,
        page: page.value,
        ordination_type: currentOrdination.type,
      }),
    )
    .then((response) => {
      requestBatches.value.push(...response.data);

      if (response.data.length < 30) {
        hasMore.value = false;
      }
    });
};

const changeOrdination = (ordination) => {
  let type = ordination.type;

  if (!ordination.active) {
    bottomSheetState.ordinationTypes.forEach((o) => (o.active = false));
    ordination.active = true;
  } else {
    ordination.active = false;
    bottomSheetState.ordinationTypes.forEach(
      (o) => o.type == "batch_number" && (o.active = true),
    );
    type = "batch_number";
  }

  if (!hasMore.value) {
    sortBatches(type);
  } else {
    getSortedBatches(type);
  }

  toggleActive();
};

const sortBatches = (type) => {
  requestBatches.value.sort((a, b) => {
    switch (type) {
      case "highest_price":
        return b.list_view.price_per_animal - a.list_view.price_per_animal;
      case "lowest_price":
        return a.list_view.price_per_animal - b.list_view.price_per_animal;
      case "highest_iabcz":
        return b.list_view.highest_zebu_index - a.list_view.highest_zebu_index;
      case "lowest_age":
        return (
          a.list_view.highest_age_in_months - b.list_view.highest_age_in_months
        );
      case "highest_age":
        return (
          b.list_view.highest_age_in_months - a.list_view.highest_age_in_months
        );
      default:
        return a.list_view.number - b.list_view.number;
    }
  });
};

const getSortedBatches = (type) => {
  page.value = page.value > 1 ? 1 : page.value;
  isLoading.value = true;

  api
    .get(
      auctions_search_batches_url({
        url_friendly: auctionUrlFriendly.value,
        page: page.value,
        ordination_type: type,
      }),
    )
    .then((response) => {
      requestBatches.value = response.data;

      if (response.data.length < 30) {
        hasMore.value = false;
      }

      isLoading.value = false;
    });
};

export {
  requestBatches,
  bottomSheetState,
  toggleActive,
  seeMore,
  hasMore,
  isLoading,
  changeOrdination,
  auctionUrlFriendly,
};
